import React, { Fragment, Component } from 'react'

import { Button, Card, CardBody, CardFooter, CardHeader, Col, Form, FormGroup, Input, Label, Row } from 'reactstrap'
import { GoogleLogin } from '@react-oauth/google'
import MicrosoftLogin from 'react-microsoft-login'
import { useLocation, withRouter } from 'react-router'

import { setSessionData } from '../../../utils/session'

import bg1 from '../../../assets/utils/images/originals/city.jpg'
import Api from '../../../utils/api'
import { passwordChangeRoutePath, passwordResetRoutePath, registerRoutePath, resetPasswordConfirmationRoutePath, signInRoutePath } from '../../../config/routePaths'
import { isValidEmail } from '../../../utils/validation'

const LoginButton = ({ children, className, onClick }) => (
  <button
    type="button"
    className={`btn-block ${className || ''}`}
    onClick={onClick}
    style={{
      alignItems: 'center',
      backgroundColor: 'rgb(255, 255, 255)',
      border: '1px solid #dadce0',
      borderRadius: '4px',
      color: '#5e5e5e',
      display: 'inlineFlex',
      fontFamily: 'Segoe UI Regular, sans-serif',
      fontSize: '14px',
      fontWeight: 500,
      height: '40px',
      padding: '0px',
      textAlign: 'center',
    }}
  >
    {children}
  </button>
)

const MicrosoftLoginButton = () => (
  <LoginButton>
    <svg version="1.1" viewBox="0 0 181.23 19" height="19">
      <title>MS-SymbolLockup</title>
      <path d="m32.812 14.082v-1.794a2.849 2.849 0 0 0 0.576 0.4 4.5 4.5 0 0 0 0.707 0.3 5.513 5.513 0 0 0 0.747 0.187 3.965 3.965 0 0 0 0.688 0.065 2.937 2.937 0 0 0 1.637-0.365 1.2 1.2 0 0 0 0.538-1.062 1.16 1.16 0 0 0-0.179-0.649 1.928 1.928 0 0 0-0.5-0.5 5.355 5.355 0 0 0-0.757-0.435q-0.437-0.209-0.935-0.436c-0.356-0.19-0.687-0.383-1-0.578a4.358 4.358 0 0 1-0.8-0.648 2.728 2.728 0 0 1-0.534-0.8 2.6 2.6 0 0 1-0.194-1.047 2.416 2.416 0 0 1 0.333-1.285 2.811 2.811 0 0 1 0.879-0.9 4.026 4.026 0 0 1 1.242-0.528 5.922 5.922 0 0 1 1.42-0.172 5.715 5.715 0 0 1 2.4 0.374v1.721a3.832 3.832 0 0 0-2.3-0.645 4.106 4.106 0 0 0-0.773 0.074 2.348 2.348 0 0 0-0.689 0.241 1.5 1.5 0 0 0-0.494 0.433 1.054 1.054 0 0 0-0.19 0.637 1.211 1.211 0 0 0 0.146 0.608 1.551 1.551 0 0 0 0.429 0.468 4.276 4.276 0 0 0 0.688 0.414c0.271 0.134 0.584 0.28 0.942 0.436q0.547 0.285 1.036 0.6a4.881 4.881 0 0 1 0.856 0.7 3.015 3.015 0 0 1 0.586 0.846 2.464 2.464 0 0 1 0.217 1.058 2.635 2.635 0 0 1-0.322 1.348 2.608 2.608 0 0 1-0.868 0.892 3.82 3.82 0 0 1-1.257 0.5 6.988 6.988 0 0 1-1.5 0.155c-0.176 0-0.392-0.014-0.649-0.04s-0.518-0.067-0.787-0.117a7.772 7.772 0 0 1-0.761-0.187 2.4 2.4 0 0 1-0.578-0.269z" fill="#5e5e5e"/>
      <path d="m42.129 5.426a1.02 1.02 0 0 1-0.714-0.272 0.89 0.89 0 0 1-0.3-0.688 0.916 0.916 0 0 1 0.3-0.7 1.008 1.008 0 0 1 0.714-0.278 1.039 1.039 0 0 1 0.732 0.278 0.909 0.909 0 0 1 0.3 0.7 0.9 0.9 0 0 1-0.3 0.678 1.034 1.034 0 0 1-0.732 0.282zm0.842 9.074h-1.7v-7.5h1.7z" fill="#5e5e5e"/>
      <path d="m52.017 13.9q0 4.131-4.153 4.131a6.187 6.187 0 0 1-2.556-0.491v-1.554a4.726 4.726 0 0 0 2.337 0.7 2.342 2.342 0 0 0 2.672-2.628v-0.818h-0.029a2.947 2.947 0 0 1-4.742 0.436 4.041 4.041 0 0 1-0.838-2.684 4.738 4.738 0 0 1 0.9-3.04 3 3 0 0 1 2.476-1.128 2.384 2.384 0 0 1 2.2 1.216h0.029v-1.04h1.7zm-1.684-2.835v-0.973a1.91 1.91 0 0 0-0.524-1.352 1.718 1.718 0 0 0-1.309-0.56 1.793 1.793 0 0 0-1.512 0.714 3.217 3.217 0 0 0-0.546 2 2.774 2.774 0 0 0 0.524 1.769 1.678 1.678 0 0 0 1.387 0.662 1.805 1.805 0 0 0 1.429-0.632 2.391 2.391 0 0 0 0.551-1.629z" fill="#5e5e5e"/>
      <path d="m60.908 14.5h-1.7v-4.227q0-2.1-1.486-2.1a1.622 1.622 0 0 0-1.282 0.582 2.162 2.162 0 0 0-0.5 1.469v4.276h-1.711v-7.5h1.707v1.245h0.029a2.673 2.673 0 0 1 2.435-1.421 2.265 2.265 0 0 1 1.868 0.795 3.57 3.57 0 0 1 0.644 2.3z" fill="#5e5e5e"/>
      <path d="m67.962 5.426a1.02 1.02 0 0 1-0.714-0.272 0.89 0.89 0 0 1-0.3-0.688 0.916 0.916 0 0 1 0.3-0.7 1.008 1.008 0 0 1 0.714-0.278 1.039 1.039 0 0 1 0.732 0.278 0.909 0.909 0 0 1 0.3 0.7 0.9 0.9 0 0 1-0.3 0.678 1.034 1.034 0 0 1-0.732 0.282zm0.838 9.074h-1.7v-7.5h1.7z" fill="#5e5e5e"/>
      <path d="m77.7 14.5h-1.7v-4.227q0-2.1-1.486-2.1a1.622 1.622 0 0 0-1.282 0.582 2.157 2.157 0 0 0-0.506 1.469v4.276h-1.703v-7.5h1.707v1.245h0.03a2.673 2.673 0 0 1 2.431-1.421 2.265 2.265 0 0 1 1.868 0.795 3.57 3.57 0 0 1 0.644 2.3z" fill="#5e5e5e"/>
      <path d="m93.984 7-2.212 7.5h-1.78l-1.361-5.083a3.215 3.215 0 0 1-0.1-0.659h-0.031a3.069 3.069 0 0 1-0.131 0.644l-1.48 5.1h-1.744l-2.206-7.502h1.761l1.363 5.405a3.16 3.16 0 0 1 0.087 0.645h0.05a3.384 3.384 0 0 1 0.117-0.659l1.515-5.391h1.6l1.347 5.428a3.732 3.732 0 0 1 0.095 0.644h0.052a3.387 3.387 0 0 1 0.11-0.644l1.329-5.428z" fill="#5e5e5e"/>
      <path d="m96.1 5.426a1.018 1.018 0 0 1-0.714-0.272 0.886 0.886 0 0 1-0.3-0.688 0.912 0.912 0 0 1 0.3-0.7 1.006 1.006 0 0 1 0.714-0.278 1.039 1.039 0 0 1 0.732 0.278 0.912 0.912 0 0 1 0.3 0.7 0.9 0.9 0 0 1-0.3 0.678 1.034 1.034 0 0 1-0.732 0.282zm0.841 9.074h-1.7v-7.5h1.7z" fill="#5e5e5e"/>
      <path d="m103.12 14.42a2.955 2.955 0 0 1-1.31 0.248q-2.182 0-2.183-2.094v-4.241h-1.253v-1.333h1.253v-1.736l1.7-0.483v2.219h1.794v1.333h-1.794v3.75a1.484 1.484 0 0 0 0.241 0.952 1.006 1.006 0 0 0 0.807 0.285 1.167 1.167 0 0 0 0.746-0.248z" fill="#5e5e5e"/>
      <path d="m111.25 14.5h-1.7v-4.1q0-2.226-1.487-2.226a1.556 1.556 0 0 0-1.26 0.644 2.568 2.568 0 0 0-0.513 1.649v4.033h-1.707v-11.1h1.707v4.849h0.029a2.685 2.685 0 0 1 2.432-1.421q2.5 0 2.5 3.055z" fill="#5e5e5e"/>
      <path d="m128.91 14.5h-1.728v-6.8q0-0.835 0.1-2.043h-0.029a6.992 6.992 0 0 1-0.285 0.988l-3.134 7.855h-1.2l-3.143-7.793a7.236 7.236 0 0 1-0.277-1.047h-0.029q0.057 0.63 0.058 2.058v6.782h-1.611v-10.5h2.453l2.762 7a10.884 10.884 0 0 1 0.41 1.2h0.036c0.181-0.551 0.327-0.962 0.44-1.23l2.811-6.97h2.366z" fill="#5e5e5e"/>
      <path d="m132.16 5.426a1.022 1.022 0 0 1-0.714-0.272 0.89 0.89 0 0 1-0.3-0.688 0.916 0.916 0 0 1 0.3-0.7 1.009 1.009 0 0 1 0.714-0.278 1.043 1.043 0 0 1 0.733 0.278 0.911 0.911 0 0 1 0.3 0.7 0.9 0.9 0 0 1-0.3 0.678 1.038 1.038 0 0 1-0.733 0.282zm0.842 9.074h-1.7v-7.5h1.7z" fill="#5e5e5e"/>
      <path d="m140.59 14.156a4.2 4.2 0 0 1-2.131 0.52 3.606 3.606 0 0 1-2.695-1.044 3.691 3.691 0 0 1-1.026-2.706 4.07 4.07 0 0 1 1.1-2.978 3.944 3.944 0 0 1 2.948-1.124 4.3 4.3 0 0 1 1.81 0.36v1.582a2.743 2.743 0 0 0-1.67-0.586 2.32 2.32 0 0 0-1.766 0.728 2.665 2.665 0 0 0-0.688 1.908 2.536 2.536 0 0 0 0.648 1.838 2.3 2.3 0 0 0 1.739 0.674 2.716 2.716 0 0 0 1.729-0.652z" fill="#5e5e5e"/>
      <path d="m146.62 8.619a1.4 1.4 0 0 0-0.887-0.242 1.513 1.513 0 0 0-1.259 0.682 3.04 3.04 0 0 0-0.506 1.852v3.589h-1.7v-7.5h1.7v1.545h0.027a2.606 2.606 0 0 1 0.766-1.233 1.724 1.724 0 0 1 1.154-0.444 1.432 1.432 0 0 1 0.7 0.14z" fill="#5e5e5e"/>
      <path d="m151.02 14.676a3.719 3.719 0 0 1-2.773-1.051 3.8 3.8 0 0 1-1.036-2.787 3.7 3.7 0 0 1 3.991-4.014 3.6 3.6 0 0 1 2.739 1.033 3.986 3.986 0 0 1 0.982 2.864 3.932 3.932 0 0 1-1.059 2.875 3.8 3.8 0 0 1-2.844 1.08zm0.08-6.5a1.938 1.938 0 0 0-1.575 0.7 2.913 2.913 0 0 0-0.579 1.919 2.744 2.744 0 0 0 0.586 1.856 1.965 1.965 0 0 0 1.568 0.678 1.87 1.87 0 0 0 1.542-0.666 2.956 2.956 0 0 0 0.538-1.9 3 3 0 0 0-0.538-1.911 1.858 1.858 0 0 0-1.542-0.672z" fill="#5e5e5e"/>
      <path d="m156.18 14.266v-1.575a3.392 3.392 0 0 0 2.1 0.725q1.539 0 1.538-0.908a0.714 0.714 0 0 0-0.132-0.436 1.241 1.241 0 0 0-0.355-0.318 2.784 2.784 0 0 0-0.527-0.25q-0.3-0.108-0.677-0.248a7.052 7.052 0 0 1-0.832-0.389 2.545 2.545 0 0 1-0.615-0.465 1.745 1.745 0 0 1-0.37-0.59 2.145 2.145 0 0 1-0.125-0.769 1.775 1.775 0 0 1 0.256-0.955 2.223 2.223 0 0 1 0.69-0.7 3.289 3.289 0 0 1 0.98-0.425 4.511 4.511 0 0 1 1.136-0.143 5.181 5.181 0 0 1 1.86 0.315v1.487a3.136 3.136 0 0 0-1.816-0.542 2.317 2.317 0 0 0-0.582 0.066 1.472 1.472 0 0 0-0.443 0.183 0.886 0.886 0 0 0-0.286 0.282 0.669 0.669 0 0 0-0.1 0.363 0.77 0.77 0 0 0 0.1 0.41 0.93 0.93 0 0 0 0.3 0.3 2.654 2.654 0 0 0 0.483 0.234q0.282 0.105 0.649 0.23a9.4 9.4 0 0 1 0.867 0.4 2.886 2.886 0 0 1 0.656 0.465 1.806 1.806 0 0 1 0.417 0.6 2.034 2.034 0 0 1 0.147 0.81 1.847 1.847 0 0 1-0.264 1 2.205 2.205 0 0 1-0.7 0.7 3.292 3.292 0 0 1-1.015 0.413 5.222 5.222 0 0 1-1.212 0.136 5.115 5.115 0 0 1-2.128-0.406z" fill="#5e5e5e"/>
      <path d="m166.44 14.676a3.717 3.717 0 0 1-2.772-1.051 3.793 3.793 0 0 1-1.036-2.787 3.7 3.7 0 0 1 3.991-4.014 3.6 3.6 0 0 1 2.739 1.033 3.986 3.986 0 0 1 0.982 2.864 3.932 3.932 0 0 1-1.059 2.875 3.8 3.8 0 0 1-2.845 1.08zm0.08-6.5a1.936 1.936 0 0 0-1.574 0.7 2.908 2.908 0 0 0-0.579 1.919 2.739 2.739 0 0 0 0.586 1.856 1.964 1.964 0 0 0 1.567 0.678 1.868 1.868 0 0 0 1.542-0.666 2.95 2.95 0 0 0 0.539-1.9 2.99 2.99 0 0 0-0.539-1.911 1.857 1.857 0 0 0-1.542-0.672z" fill="#5e5e5e"/>
      <path d="m176.07 4.781a1.533 1.533 0 0 0-0.784-0.2q-1.237 0-1.237 1.4v1.019h1.743v1.333h-1.736v6.167h-1.7v-6.167h-1.282v-1.333h1.282v-1.216a2.362 2.362 0 0 1 0.777-1.871 2.82 2.82 0 0 1 1.94-0.684 2.879 2.879 0 0 1 1 0.138z" fill="#5e5e5e"/>
      <path d="m181.23 14.42a2.955 2.955 0 0 1-1.31 0.248q-2.182 0-2.183-2.094v-4.241h-1.253v-1.333h1.253v-1.736l1.7-0.483v2.219h1.793v1.333h-1.793v3.75a1.484 1.484 0 0 0 0.241 0.952 1 1 0 0 0 0.806 0.285 1.165 1.165 0 0 0 0.746-0.248z" fill="#5e5e5e"/>
      <rect width="9" height="9" fill="#f25022"/>
      <rect y="10" width="9" height="9" fill="#00a4ef"/>
      <rect x="10" width="9" height="9" fill="#7fba00"/>
      <rect x="10" y="10" width="9" height="9" fill="#ffb900"/>
    </svg>
  </LoginButton>
)

const EmailWithPasswordForm = ({ isRegister }) => {
  const location = useLocation()

  const [email, setEmail] = React.useState('')
  const [password, setPassword] = React.useState('')
  const [error, setError] = React.useState('')
  const [busy, setBusy] = React.useState(false)

  const reset = () => {
    setEmail('')
    setPassword('')
    setError('')
  }

  React.useEffect(() => {
    reset()
  }, [location.pathname])

  React.useEffect(() => {
    if (error) {
      setError('')
    }
  }, [email, password])

  const handleSubmit = async () => {
    if (busy) {
      return
    }

    const payload = {
      email: email.trim(),
      forms_login: true,
      password: password.trim(),
    }

    if (!payload.email) {
      setError('Email is required')
      return
    }

    if (!payload.password) {
      setError('Password is required')
      return
    }

    const endpoint = isRegister ? registerRoutePath : signInRoutePath

    const options = {
      onFailure: (response, json) => {
        setBusy(false)

        if (json.status) {
          setError(json.status)
        } else {
          setError(response.statusText)
        }
      },
    }

    setBusy(true)
    setError('')

    const data = await Api.post(endpoint, payload, options)

    const { jwt_token } = data
    setSessionData(jwt_token)
    window.location.href = '/'
  }

  const handleKeyDown = e => {
    if (e.key === 'Enter') {
      handleSubmit()
    }
  }

  return (
    <Card className='main-card mb-6'>
      <CardHeader>{isRegister ? 'Register New Account' : 'Sign In with Password'}</CardHeader>
      <CardBody>
        <Form onSubmit={handleSubmit}>
          <FormGroup>
            <Label>Email</Label>
            <Input
              autoFocus
              disabled={busy}
              name="email"
              onChange={e => setEmail(e.currentTarget.value)}
              onKeyDown={handleKeyDown}
              required
              type="email"
              value={email}
            />
          </FormGroup>
          <FormGroup>
            <Label>Password</Label>
            <Input
              disabled={busy}
              name="password"
              onChange={e => {
                setPassword(e.currentTarget.value)
              }}
              onKeyDown={handleKeyDown}
              required
              type="password"
              value={password}
            />
          </FormGroup>
        </Form>
      </CardBody>
      <CardFooter className='text-center d-block p-3'>
        <div style={{ alignItems: 'center', display: 'flex', gap: '1rem', justifyContent: isRegister ? 'center' : 'space-between' }}>
          <Button
            color="success"
            className="btn-pill btn-shadow btn-wide fsize-1"
            disabled={busy || error}
            onClick={handleSubmit}
            size="lg"
          >
            <span className="mr-2 opacity-7"></span>
            <span className="mr-1">{isRegister ? 'Register' : 'Sign In'}</span>
          </Button>

          {!isRegister && (
            <Button
              color="link"
              onClick={() => {
                window.location.href = passwordResetRoutePath
              }}
            >
              Reset Password
            </Button>
          )}
        </div>

        {!!error && (
          <div className="text-danger mt-3">
            {error}
          </div>
        )}
      </CardFooter>
    </Card>
  )
}

const PasswordResetForm = () => {
  const location = useLocation()

  const [email, setEmail] = React.useState('')
  const [error, setError] = React.useState('')
  const [busy, setBusy] = React.useState(false)
  const [didSend, setDidSend] = React.useState(false)

  const reset = () => {
    setEmail('')
    setError('')
  }

  React.useEffect(() => {
    reset()
  }, [location.pathname])

  React.useEffect(() => {
    if (error) {
      setError('')
    }
  }, [email])

  const handleSubmit = async () => {
    if (busy) {
      return
    }

    const payload = {
      email: email.trim(),
    }

    if (!payload.email) {
      setError('Email is required')
      return
    }

    if (!isValidEmail(payload.email)) {
      setError('Email is not valid')
      return
    }

    const options = {
      onFailure: (response, json) => {
        setBusy(false)

        if (json.status) {
          setError(json.status)
        } else {
          setError(response.statusText)
        }
      },
    }

    setBusy(true)
    setError('')

    const data = await Api.post(passwordResetRoutePath, payload, options)

    if (data.status === 'sent') {
      setDidSend(true)
    } else {
      setBusy(false)
    }
  }

  const handleKeyDown = e => {
    if (e.key === 'Enter') {
      handleSubmit()
    }
  }

  return (
    <Card className='main-card mb-6'>
      <CardHeader>Reset Password</CardHeader>
      <CardBody>
        <Form onSubmit={handleSubmit}>
          <FormGroup>
            <Label>Email</Label>
            <Input
              autoFocus
              disabled={busy}
              name="email"
              onChange={e => setEmail(e.currentTarget.value)}
              onKeyDown={handleKeyDown}
              required
              type="email"
              value={email}
            />
          </FormGroup>
        </Form>
      </CardBody>
      <CardFooter className='text-center d-block p-3'>
        <Button
          color="success"
          className="btn-pill btn-shadow btn-wide fsize-1"
          disabled={busy || error}
          onClick={handleSubmit}
          size="lg"
        >
          <span className="mr-2 opacity-7"></span>
          <span className="mr-1">Request Password Reset</span>
        </Button>

        <Button
          color="link"
          onClick={() => {
            window.location.href = signInRoutePath
          }}
        >
          Cancel
        </Button>

        {
          error
            ? (
              <div className="text-danger mt-3">
                {error}
              </div>
            )
            : didSend && (
              <div className="text-success mt-3">
                If an account with that email exists, you will receive an email with instructions to reset your password.
              </div>
            )
        }
      </CardFooter>
    </Card>
  )
}

const ResetPasswordConfirmationForm = () => {
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)

  const token = queryParams.get('reset_password_token')

  if (!token) {
    window.location.href = signInRoutePath
    return null
  }

  const [password, setPassword] = React.useState('')
  const [passwordConfirm, setPasswordConfirm] = React.useState('')
  const [error, setError] = React.useState('')
  const [busy, setBusy] = React.useState(false)

  const reset = () => {
    setPassword('')
    setPasswordConfirm('')
    setError('')
  }

  React.useEffect(() => {
    reset()
  }, [location.pathname])

  React.useEffect(() => {
    if (error) {
      setError('')
    }
  }, [password, passwordConfirm])

  const handleSubmit = async () => {
    if (busy) {
      return
    }

    const payload = {
      password: password.trim(),
      password_confirmation: passwordConfirm.trim(),
      reset_password_token: token,
    }

    if (!payload.password) {
      setError('Password is required')
      return
    }

    if (!payload.password_confirmation) {
      setError('Confirm password is required')
      return
    }

    if (password !== passwordConfirm) {
      setError('Password does not match confirm password')
      return
    }

    const options = {
      onFailure: (response, json) => {
        setBusy(false)

        if (json.status) {
          setError(json.status)
        } else if (json.errors) {
          setError(Object.values(json.errors).join(', '))
        } else {
          setError(response.statusText)
        }
      },
    }

    setBusy(true)
    setError('')

    await Api.post(passwordChangeRoutePath, payload, options)

    window.location.href = signInRoutePath
  }

  const handleKeyDown = e => {
    if (e.key === 'Enter') {
      handleSubmit()
    }
  }

  return (
    <Card className='main-card mb-6'>
      <CardHeader>Confirm Reset Password</CardHeader>
      <CardBody>
        <Form onSubmit={handleSubmit}>
          <FormGroup>
            <Label>Password</Label>
            <Input
              autoFocus
              disabled={busy}
              name="email"
              onChange={e => setPassword(e.currentTarget.value)}
              onKeyDown={handleKeyDown}
              required
              type="password"
              value={password}
            />
          </FormGroup>
          <FormGroup>
            <Label>Confirm Password</Label>
            <Input
              disabled={busy}
              name="email"
              onChange={e => setPasswordConfirm(e.currentTarget.value)}
              onKeyDown={handleKeyDown}
              required
              type="password"
              value={passwordConfirm}
            />
          </FormGroup>
        </Form>
      </CardBody>
      <CardFooter className='text-center d-block p-3'>
        <Button
          color="success"
          className="btn-pill btn-shadow btn-wide fsize-1"
          disabled={busy || error}
          onClick={handleSubmit}
          size="lg"
        >
          <span className="mr-2 opacity-7"></span>
          <span className="mr-1">Set New Password</span>
        </Button>

        {!!error && (
          <div className="text-danger mt-3">
            {error}
          </div>
        )}
      </CardFooter>
    </Card>
  )
}

class Login extends Component {
  constructor(props) {
    super(props)

    this.state = { useSSO: false, error: '', email: '', password: '', action: 'blah', buttonDisabled: false, disableOnSubmit: false, microsoft_token: '', google_token: '', accountName: '' }
  }

  componentDidMount() {
    var self = this
    window.addEventListener('message', (message) => {
      setTimeout(() => {
        try {
          if (message.data?.startsWith?.('id_token=')) {
            var key = message.data.split('id_token=')[1]
            self.setState({ microsoft_token: key })
            self.login(null)
          }
        } catch (err) {
          console.dir(err) // eslint-disable-line no-console
        }
      }, 100)
    }, false)
  }

  responseGoogle = (r) => {
    if (r.credential) {
      this.setState({ google_token: r.credential})
      this.login(null)
    } else {
      console.dir(r) // eslint-disable-line no-console
    }
  }

  responseMicrosoft = (err, r) => {
    if (err) {
      console.dir(err) // eslint-disable-line no-console
    }
    if (r?.accessToken) {
      this.setState({ /* email: r.account.username, */ microsoft_token: r.accessToken })
      this.login(null)
    } else {
      console.dir(r) // eslint-disable-line no-console
    }
  }

  handleChange = (e, field) => {
    var newState = {}
    newState[field] = e.target.value
    this.setState(newState)
  }

  login = (e /* , options = { microsoft_token: null } */) => {
    if (e) {
      e.preventDefault()
      e.stopPropagation()
    }

    if (this.state.disableOnSubmit) {
      this.setState({ buttonDisabled: true })
    }

    // this.token = (document.head.querySelector("[property=csrf-token]") as unknown as any).content;

    var self = this

    var payload = { user: {} }
    payload['email'] = this.state.email
    payload['user']['email'] = this.state.email

    if (this.state.google_token) {
      payload['google_token'] = this.state.google_token
    }

    if (this.state.microsoft_token) {
      payload['microsoft_token'] = this.state.microsoft_token
    }

    if (this.state.password.length > 0) {
      payload['password'] = this.state.password
      payload['user']['password'] = this.state.password
    }

    Api.post(signInRoutePath, payload)
      .then(responseJSON => {
        if (responseJSON.key) {
          window.parent.postMessage('key=' + responseJSON.key, '*')
        } else if (responseJSON.jwt_token) {
          const jwt = responseJSON.jwt_token
          setSessionData(jwt)
          window.location.href = '/'
        } else {
          if (responseJSON.errors) {
            self.setState({
              buttonDisabled: false,
              error: Object.keys(responseJSON.errors)[0] + ' ' + responseJSON.errors['email'],
            })
          } else if (responseJSON.status) {
            self.setState({
              buttonDisabled: false,
              error: responseJSON.status,
            })
          }

          self.props.history.push(responseJSON.redirect_to)
        }
      })

    return false
  }

  render() {
    const { history, location } = this.props

    var self = this
    if (window.location.hash) {
      if (window.location.hash.startsWith('#id_token=')) {
        var id_token = window.location.hash.split('#id_token=')[1].split('&')[0]
        window.parent.postMessage('id_token=' + id_token, '*')
      }
    }
    // let settings = {
    //   dots: true,
    //   infinite: true,
    //   speed: 500,
    //   arrows: true,
    //   slidesToShow: 1,
    //   slidesToScroll: 1,
    //   fade: true,
    //   initialSlide: 0,
    //   autoplay: true,
    //   adaptiveHeight: true,

    // }

    const isRegister = location.pathname === registerRoutePath
    const isPasswordReset = location.pathname === passwordResetRoutePath

    const isResetPasswordConfirmation = location.pathname === resetPasswordConfirmationRoutePath

    return (
      <Fragment>
        <div className="h-100" >
          <Row className="h-100 no-gutters" >
            <Col lg="4" className="d-none d-lg-block" >
              <div className="slider-light slick-slider" >

                <div
                  className="h-100 d-flex justify-content-center align-items-center bg-plum-plate" >
                  <div className="slide-img-bg"
                    style={{
                      backgroundImage: 'url(' + bg1 + ')',
                    }
                    } />
                  <div className="slider-content" >
                    <h3>ExpanDrive for Teams</h3>
                    <p>
                      Manage your ExpanDrive deployment for your team
                    </p>
                  </div>
                </div>

              </div>
            </Col>
            <Col lg="8" md="12" className="h-100 d-flex bg-white justify-content-center align-items-center" >
              <Col lg="9" md="10" sm="12" className="mx-auto app-login-box" >
                {
                  isResetPasswordConfirmation
                    ? <ResetPasswordConfirmationForm />
                    : (
                      <>
                        <div>
                          {
                            isPasswordReset
                              ? <PasswordResetForm />
                              : <EmailWithPasswordForm isRegister={isRegister} />
                          }
                        </div>

                        <div className="hr-label"><span className="hr-label__text">or</span></div>

                        <div>
                          <h5 className="mb-3" style={{ textAlign: 'left', color: '#888', fontSize: '14px', 'marginTop': '0rem' }} >
                            {isRegister ? 'Register' : 'Sign In'} with Single Sign-On
                          </h5>

                          <div className="d-flex" style={{ flexDirection: 'column', gap: '1rem' }} >
                            <GoogleLogin
                              className={'btn-block'}
                              prompt={'select_account'}
                              buttonText="Sign in with Google"
                              onSuccess={this.responseGoogle}
                              onFailure={this.responseGoogle}
                              cookiePolicy={'single_host_origin'}

                            />

                            <MicrosoftLogin
                              prompt={'select_account'}
                              withUserData
                              className={'btn-block'}
                              redirectUri={window.location.href}
                              clientId={process.env.MICROSOFT_CLIENT_ID}
                              authCallback={self.responseMicrosoft.bind(self)}
                            >
                              <MicrosoftLoginButton />
                            </MicrosoftLogin>
                          </div>
                        </div>

                        <div className="hr-label"><span className="hr-label__text">or</span></div>

                        {
                          isRegister
                            ? (
                              <div>
                                <h5 className="mb-3" style={{ textAlign: 'left', color: '#888', fontSize: '14px', 'marginTop': '0rem' }} >
                                  Have an existing account?
                                </h5>
                                <div style={{ alignItems: 'flex-start', display: 'flex', gap: '1rem', justifyContent: 'space-between' }} >
                                  <LoginButton onClick={() => history.push(signInRoutePath)}>
                                    Sign In with Password
                                  </LoginButton>
                                  <LoginButton className="mt-0" onClick={() => history.push(passwordResetRoutePath)}>
                                    Reset Password
                                  </LoginButton>
                                </div>
                              </div>
                            )
                            : (
                              <div>
                                <h5 className="mb-3" style={{ textAlign: 'left', color: '#888', fontSize: '14px', 'marginTop': '0rem' }} >
                                  Don&apos;t have an account?
                                </h5>
                                <LoginButton onClick={() => history.push(registerRoutePath)}>
                                  Register New Account
                                </LoginButton>
                              </div>
                            )
                        }

                        <h4 className="mb-0" style={{ textAlign: 'left', color: 'rgb(100, 100, 100)', fontSize: '12px', 'marginTop': '0rem' }} >
                          {
                            this.state.error && (
                              <span><br />{this.state.error}</span>
                            )
                          }
                        </h4>
                      </>
                    )
                }
              </Col>
            </Col>
          </Row>
        </div>
      </Fragment>
    )
  }
}

export default withRouter(Login)
